<style>
.avatar-img,
.avatar-load,
.avatar-error {
  width: 100% !important;
  min-height: 80px !important;
  max-height: 150px !important;
  border-radius: 0.375rem !important;
  overflow: hidden !important;
}

.avatar-img img,
.avatar-error img {
  min-height: 100px !important;
  width: 100% !important;
  height: 100% !important;
  object-fit: cover !important;
}
</style>
<template>
  <div class="avatar-view">
    <div v-if="avatarURL != null && avatarLoaded != false" class="avatar-img">
      <img :src="avatarURL" />
    </div>

    <div v-if="avatarURL == null && avatarLoaded == false" class="avatar-load">
      <content-loader :width="80" :height="80" :speed="2" primaryColor="#b0b0b0" secondaryColor="#ecebeb">
        <rect x="1" y="1" rx="6" ry="6" width="80" height="80" />
      </content-loader>
    </div>

    <div v-if="avatarURL == null && avatarLoaded == true" class="avatar-error">
      <!-- <img :src="require('@assets/img/defaultUser.png')" /> -->
      <avatar :username="name" :rounded="false" class="avatar-img"></avatar>
    </div>
  </div>
</template>

<script>
import Avatar from 'vue-avatar'
import { getAvatar } from "@assets/js/userController";
import { ContentLoader } from "vue-content-loader";

export default {
  name: 'AvatarViewComponent',
  data() {
    return {
      avatarURL: null,
      avatarLoaded: false,
      avatarError: false
    }
  },
  props: {
    name: {
      type: String,
      required: true
    }
  },
  components: {
    ContentLoader,
    Avatar
  },
  methods: {
    async getAvatar() {
      const avatar = await getAvatar();
      return avatar;
    },
    async loadAvatar() {
      try {
        const avatarURL = this.$store.getters.avatar;

        if (avatarURL == null) {
          const avatar = await this.getAvatar();
          this.$store.commit('setAvatar', avatar);
          this.avatarLoaded = true;
          return avatar;
        }

        this.avatarLoaded = true;
        return avatarURL;

      } catch (error) {
        this.avatar = null;
        this.avatarLoaded = true;
      }
    }
  },
  async mounted() {
    this.avatarURL = await this.loadAvatar();
  }
}
</script>