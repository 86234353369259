<template>
  <div class="container-fluid" style="padding: 20px">
    <div class="row">
      <div class="col">
        <slot name="menu"></slot>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <slot name="content"></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {

}
</script>