import * as mdb from 'mdb-ui-kit/src/js/mdb.free'

import Vue from "vue";
import Buefy from "buefy";
import VueMeta from 'vue-meta'
import router from "./router";
import store from "./store";
import App from "./App.vue";
import i18n from './i18n'
import firebase from "./firebase";
import VueCookies from 'vue-cookies'
import Vuetify from 'vuetify/lib/framework';
import ScrollLoader from 'vue-scroll-loader'
import vueCustomElement from 'vue-custom-element'
import VCalendar from 'v-calendar';

import { firestorePlugin } from 'vuefire'
import { BootstrapVue } from 'bootstrap-vue'
import { loadRemoteConfig } from './assets/js/remoteConfig';

import GlobalComponents from './plugins/GlobalComponents';
import BuefySimplified from './plugins/BuefySimplified';
import ShoppingCart from './plugins/ShoppingCart';

Vue.use(BuefySimplified);
Vue.use(GlobalComponents);
Vue.use(ShoppingCart);

Vue.use(Vuetify);
Vue.use(firestorePlugin);
Vue.use(VueMeta);
Vue.use(ScrollLoader);
Vue.use(BootstrapVue);
Vue.use(vueCustomElement);
Vue.use(VueCookies);
Vue.use(VCalendar);

Vue.use(Buefy, {
    defaultIconPack: 'fas',
    defaultProgrammaticPromise: true,
    defaultSnackbarPosition: 'is-bottom',
    defaultToastPosition: 'is-bottom',
    defaultTooltipType: 'is-dark'
});


import '@modules/@fortawesome/fontawesome-free/css/all.min.css'
import '@assets/css/index.scss'
import '@assets/scss/mdb.free.scss'
import '@assets/scss/buefy-build.scss'
import '@assets/scss/animations.scss'
import '@src/registerServiceWorker'

Vue.config.productionTip = false;
Vue.prototype.$mdb = mdb;
Vue.prototype.$firebaseApp = firebase;

new Vue({
    render: h => h(App),
    i18n,
    router: router,
    Vuetify,
    store: store,
    async created() {

        // const loader = this.$buefy.loading.open();
        const timeout = setTimeout(() => {
            // loader.close();
            this.$BuefySimplified.Toast.Danger('Loading page took longer than expected. Please try again.');
        }, 5000);

        this.$store.dispatch('loadUser');
        await loadRemoteConfig();

        clearTimeout(timeout);
        // loader.close();
    }
}).$mount('#app');