<template>
  <ul class="list-group" :class="`${customClass} ${darkMode}`">
    <template v-for="item in items">
      <li :key="item.id" class="list-group-item" :class="darkMode">{{item.title}}</li>
    </template>
  </ul>
</template>

<script>
import { darkModeStyle } from '@assets/js/darkMode';

export default {
  props: {
    items: {
      type: Array,
      required: true
    },
    isDark: {
      type: Boolean,
      required: false,
      default: false
    },
    customClass: {
      type: String,
      required: false,
      default: ''
    }
  },
  data() {
    return {
      darkMode: darkModeStyle(),
      darkModeText: darkModeStyle('text-light', 'text-dark'),
    }
  }
}
</script>