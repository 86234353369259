import $ from 'jquery'
import Darkmode from 'drkmd-js'

const options = {
  localStorage: true
}

const page = window.location.pathname;

if (page != '/login') {
  new Darkmode().attach(options)
}


window.addEventListener('theme-change', e => {
  const darkMode = e.detail.to === 'dark'

  if (darkMode) {
    window.darkTrue()
  } else {
    window.darkFalse()
  }
})

window.darkFalse = function darkFalse() {
  $('.date-list-item_dark').removeClass('date-list-item_dark').addClass('date-list-item_light')
  $('.bg-dark').removeClass('bg-dark').addClass('bg-light')
  $('.navbar-dark').removeClass('navbar-dark').addClass('navbar-light')
  $('.text-light').removeClass('text-light').addClass('text-dark')
  $('.btn-dark').removeClass('btn-dark').addClass('btn-light')
  $('.badge-dark').removeClass('badge-dark').addClass('badge-light')
  $('.form-gray').removeClass('form-gray').addClass('form-dark')
  $('html').attr('style', 'background-color: #e4e4e4 !important')
  $('body').attr('style', 'background-color: #e4e4e4 !important')
}

window.darkTrue = function darkTrue() {
  $('.date-list-item_light').removeClass('date-list-item_light').addClass('date-list-item_dark')
  $('.bg-light').removeClass('bg-light').addClass('bg-dark')
  $('.navbar-light').removeClass('navbar-light').addClass('navbar-dark')
  $('.text-dark').removeClass('text-dark').addClass('text-light')
  $('.btn-light').removeClass('btn-light').addClass('btn-dark')
  $('.badge-light').removeClass('badge-light').addClass('badge-dark')
  $('.form-dark').removeClass('form-dark').addClass('form-gray')
  $('html').attr('style', 'background-color: #1b1c1e !important')
  $('body').attr('style', 'background-color: #1b1c1e !important')
}

export function getDarkMode() {
  const darkmode = new Darkmode()
  const darkTF = darkmode.currentTheme() === 'dark'

  return darkTF
}

export function darkModeStyle(styleDark, styleLight) {
  const darkmode = new Darkmode()
  const darkTF = darkmode.currentTheme() === 'dark'

  if (typeof styleDark === 'undefined') {
    styleDark = 'bg-dark text-light'
  }

  if (typeof styleLight === 'undefined') {
    styleLight = 'bg-light text-dark'
  }

  return darkTF ? styleDark : styleLight
}
