<template>
  <div>
    <NavBar></NavBar>
    <router-view></router-view>
    <LoginModel></LoginModel>
    <OfflineBar></OfflineBar>
  </div>
</template>

<script>
import '@assets/js/darkMode'
import '@assets/css/cart.css'

export default {
  name: 'App',
  components: {
    NavBar: () => import('@components/NavBar.vue'),
    LoginModel: () => import('@src/components/LoginModel.vue'),
    OfflineBar: () => import('@components/OfflineBar.vue'),
  },
  mounted() {
    console.log('App mounted')

    if (typeof this.$route.query.info_message !== 'undefined') {
      this.$buefy.snackbar.open({
        indefinite: true,
        message: this.$route.query.info_message,
        type: 'is-warning',
        position: 'is-bottom',
        actionText: 'Close'
      })

      let query = Object.assign({}, this.$route.query);
      delete query.info_message;
      this.$router.replace({ query });
    }

    if (typeof this.$route.query.redirect !== 'undefined') {
      this.$router.push({
        path: this.$route.query.redirect,
      });
    }
  }
}
// https://ashleysparty.page.link/?link=https://test.ashleybailey.me/?redirect=/order/menu/item/BRYVO23PHV2XR2UPWEOSEGJR&ibi=com.ashleybailey.ashleysparty&isi=1586383214
</script>
