<template>
  <ul class="list-group list-group-flush bg-none" :class="darkModeText">
    <template v-for="event in events">
      <router-link tag="li" :to="event.url" :key="event.id" class="list-group-item bg-none p-2 pb-4 pt-4" :class="`${darkModeText} ${customClass}`">
        <div class="row">
          <div class="col-2 pl-0">
            <div class="mt-1" :class="darkModeCal">
              <div class="month">{{getMonth(event.date)}}</div>
              <div class="day">{{getDay(event.date)}}</div>
            </div>
          </div>
          <div class="col-9">
            <div class="row">
              <div class="col">{{event.title}}</div>
            </div>
            <div class="row">
              <div class="col">
                <div class="noline">{{event.description}}</div>
              </div>
            </div>
          </div>
          <div class="col-1 d-flex justify-content-end align-items-center">
            <i class="fa fa-chevron-right pr-2"></i>
          </div>
        </div>
        <div class="gradient-line"></div>
      </router-link>
    </template>
  </ul>
</template>

<script>
import { darkModeStyle } from '@assets/js/darkMode';

export default {
  props: {
    events: {
      type: Array,
      required: true
    },
    customClass: {
      type: String,
      default: ''
    },
  },
  data() {
    return {
      darkMode: darkModeStyle(),
      darkModeText: darkModeStyle('text-light', 'text-dark'),
      darkModeCal: darkModeStyle('date-list-item_light', 'date-list-item_dark'),
    }
  },
  methods: {
    getMonth(date) {
      return new Date(date).toLocaleString('default', { month: 'short' });
    },
    getDay(date) {
      return new Date(date).toLocaleString('default', { day: 'numeric' });
    },
  }
}
</script>