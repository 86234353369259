import {
    addItemToCart,
    setCountForItem,
    removeItemFromCart,
    removeItemFromCartAll,
    clearCart,
    totalCount,
    totalCart,
    listCart,
    saveCart,
    loadCart
} from './shoppingCart';

const obj = {
    addItemToCart,
    setCountForItem,
    removeItemFromCart,
    removeItemFromCartAll,
    clearCart,
    totalCount,
    totalCart,
    listCart,
    saveCart,
    loadCart
};

export default {
    install(Vue) {
        Vue.prototype.$shoppingCart = obj;
    }
};