import { ToastProgrammatic as Toast } from "buefy";

var SimpleToast = {};

SimpleToast.Danger = function(message, queue = true) {
    Toast.open({
        message,
        type: 'is-danger',
        queue,
        position: 'is-bottom',
        duration: 5000
    });
}

SimpleToast.Success = function(message, queue = true) {
    Toast.open({
        message,
        type: 'is-success',
        queue,
        position: 'is-bottom',
        duration: 5000
    });
}

SimpleToast.Warning = function(message, queue = true) {
    Toast.open({
        message,
        type: 'is-warning',
        queue,
        position: 'is-bottom',
        duration: 5000
    });
}

SimpleToast.Info = function(message, queue = true) {
    Toast.open({
        message,
        type: 'is-info',
        queue,
        position: 'is-bottom',
        duration: 5000
    });
}

export default SimpleToast;