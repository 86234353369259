import SimpleToast from './Toast';

var obj = {
    Toast: { ...SimpleToast },
};


export default {
    install(Vue) {
        Vue.prototype.$BuefySimplified = obj;
    }
};