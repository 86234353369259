<template>
  <div class="card bg-gray p-2 mt-2">
    <router-link tag="div" :to="url">
      <div class="row">
        <div class="col-2 pl-0">
          <div class="mt-1" :class="darkModeCal">
            <div class="month">{{getMonth(date)}}</div>
            <div class="day">{{getDay(date)}}</div>
          </div>
        </div>
        <div class="col-8" style="font-size: 12px; display: flex; flex-direction: column; justify-content: center;">
          <div class="row">
            <div class="col">{{name}}</div>
          </div>
          <div class="row">
            <div class="col">
              <div class="noline">{{description}}</div>
            </div>
          </div>
        </div>
        <div class="col-2 d-flex justify-content-end align-items-center">{{toPrice(price)}}</div>
      </div>
    </router-link>

    <b-loading :is-full-page="false" :active="disabled">
      <h5 class="m-0 text-black">Out of Tickets</h5>
    </b-loading>
  </div>
</template>

<script>
import { darkModeStyle } from '@assets/js/darkMode';
import Dinero from 'dinero.js';
import { getRemoteConfigValue } from '@assets/js/remoteConfig';

export default {
  props: {
    id: {
      type: String,
      required: true
    },
    url: {
      type: String,
      required: true
    },
    name: {
      type: String,
      required: true
    },
    description: {
      type: String,
      required: true
    },
    date: {
      type: Date,
      required: true
    },
    price: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      disabled: true,
      darkMode: darkModeStyle(),
      darkModeText: darkModeStyle('text-light', 'text-dark'),
      darkModeCal: darkModeStyle('date-list-item_light', 'date-list-item_dark'),
      snackbar: null
    }
  },
  methods: {
    async inStock(variationID) {
      try {

        const apiURL = await getRemoteConfigValue('APIurl');

        const stockData = await fetch(`${apiURL.asString()}/api/products/${variationID}/stock`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "csrf-token": this.$cookies.get('XSRF-TOKEN')
          }
        });

        const stockJsonData = await stockData.json();
        console.log(stockJsonData.counts[0].state != "IN_STOCK")
        return stockJsonData.counts[0].state != "IN_STOCK";

      } catch (e) {
        this.snackbar = this.$buefy.snackbar.open({
          message: "Ticket information may be unavalible.",
          type: 'is-danger',
          position: 'is-bottom',
          queue: false,
          indefinite: true
        });

        return true;
      }
    },
    getMonth(date) {
      return new Date(date).toLocaleString('default', { month: 'short' });
    },
    getDay(date) {
      return new Date(date).toLocaleString('default', { day: 'numeric' });
    },
    toPrice(price) {
      return Dinero({ amount: price, currency: 'GBP' }).toFormat()
    },
  },
  async mounted() {
    this.disabled = await this.inStock(this.id);
  },
  destroyed() {
    if (this.snackbar != null) {
      this.snackbar.close();
    }
  }
}
</script>