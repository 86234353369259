import store from "@src/store";

// =============================
// Private methods and propeties
// =============================

// Constructor
class Item {
  constructor(id, name, price, count, category) {
    this.id = id
    this.name = name
    this.price = price
    this.count = count
    this.category = category
  }
}

// Save cart
export function saveCart(cartArray) {
  store.commit('setCart', cartArray)
}

// Load cart
export function loadCart() {

  // Get cart from store
  const storeCart = store.state.shoppingCart

  // If cart is not empty
  if (typeof storeCart != 'undefined' && storeCart.length > 0) {
    // Log cart
    console.log('Cart loaded from store: ', storeCart)

    // Return cart
    return storeCart;
  }

  // Get cart from local storage
  const cartLS = localStorage.getItem('shoppingCart');

  // If cart is not empty
  if (cartLS != null) {
    // Parse cart from local storage
    const cart = JSON.parse(cartLS);

    // Log cart
    console.log('Cart loaded from local storage: ', cart)

    // Save cart to store
    store.commit('setCart', cart)

    // Parse cart from local storage
    return cart;
  }

  // Return empty cart
  return [];
}

let cart = loadCart();

// =============================
// Public methods and propeties
// =============================

// Add to cart
export function addItemToCart(id, name, price, count, category) {
  for (var item in cart) {
    if (cart[item].id === id) {
      cart[item].count += count
      saveCart(cart)
      return
    }
  }

  // Session data
  var pushItem = new Item(id, name, price, count, category)
  cart.push(pushItem)
  saveCart(cart)
}

// Set count from item
export function setCountForItem(id, count) {
  for (var i in cart) {
    if (cart[i].id === id) {
      cart[i].count = count
      break
    }
  }

  saveCart(cart);
}

// Remove item from cart
export function removeItemFromCart(id) {
  for (var item = 0; item < cart.length; item++) {
    if (cart[item].id === id) {
      cart[item].count--
      if (cart[item].count === 0) {
        cart.splice(item, 1)
      }
      break
    }
  }
  saveCart(cart)
}

// Remove all items from cart
export function removeItemFromCartAll(id) {
  for (var item = 0; item < cart.length; item++) {
    if (cart[item].id === id) {
      cart.splice(item, 1)
      break
    }
  }
  saveCart(cart)
}

// Clear cart
export function clearCart() {
  cart = []
  saveCart(cart)
}

// Count cart
export function totalCount() {
  var totalCount = 0
  for (var item in cart) {
    totalCount += cart[item].count
  }
  return totalCount
}

// Total cart
export function totalCart() {
  var totalCart = 0
  for (var item in cart) {
    totalCart += cart[item].price * cart[item].count
  }
  return Number(totalCart.toFixed(2))
}

// List cart
export function listCart() {
  var cartCopy = []

  for (const i in cart) {
    const item = cart[i]
    var itemCopy;
    for (const p in item) {
      itemCopy[p] = item[p]
    }
    itemCopy.total = item.price * item.count
    cartCopy.push(itemCopy)
  }
  return cartCopy;
}

// cart : Array
// Item : Object/Class
// addItemToCart : Function
// removeItemFromCart : Function
// removeItemFromCartAll : Function
// clearCart : Function
// countCart : Function
// totalCart : Function
// listCart : Function
// saveCart : Function
// loadCart : Function