import TitleComponent from './TitleComponent.vue';
import ContainerFluidComponent from './ContainerFluidComponent.vue';
import ListViewComponent from './ListViewComponent.vue';
import CalanderListComponent from './CalanderListComponent.vue';
import TicketViewComponent from './TicketViewComponent.vue';
import AvatarViewComponent from './AvatarViewComponent.vue';

export default {
    install(Vue) {
        Vue.component('main-title', TitleComponent);
        Vue.component('container-fluid', ContainerFluidComponent);
        Vue.component('list-view', ListViewComponent);
        Vue.component('calander-list', CalanderListComponent);
        Vue.component('ticket-view', TicketViewComponent);
        Vue.component('avatar-view', AvatarViewComponent);
    }
};