<template>
  <component :is="tag" v-bind:class="`${darkModeText} ${customClass} ${!noMargin ? 'ml-1 mb-2' : ''}`">{{text}}</component>
</template>
<script>
import { darkModeStyle } from '@assets/js/darkMode';

export default {
  props: {
    text: {
      required: true,
      type: String
    },
    tag: {
      required: false,
      type: String,
      default: 'h5'
    },
    noMargin: {
      required: false,
      type: Boolean,
      default: false
    },
    customClass: {
      required: false,
      type: String,
      default: ''
    },
  },
  data() {
    return {
      darkModeText: darkModeStyle('text-light', 'text-dark'),
    }
  },
}
</script>